import React, { useState, useEffect, useMemo, useRef, useReducer } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoAddCircleSharp, IoClose } from "react-icons/io5";
import { BiPencil } from "react-icons/bi";
import { AiFillMinusCircle } from "react-icons/ai";
import { GrStatusUnknown } from "react-icons/gr";

import styles from "styles/admin/integration/integrationStepBuilder.module.css";
import DictRow from "./dictRow";
import { query } from "firebase/firestore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { IoMdNotifications, IoMdAdd } from "react-icons/io";
import { AiOutlineMinus } from "react-icons/ai";

import JSONEditorInstance from "./jsonEditorInstance";
import Deviation from "./deviation";
import { IntegrationReducer, ACTIONS } from "./integrationReducer";
import integrationViewModel from "./integrationViewModel";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toCamelCaseFormat } from "global/globalFunctions";
import { testWebhook } from "services/webhookService";
import Loading from "components/global/loading";
import {
  getIntegration,
  updateIntegrationDb,
  addIntegrationDb,
} from "../../../services/integrationService";
import { updateCompanyIntentFields } from "services/companyService";
import { getApiAgentsByCompany } from "services/agentService";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";

const IntegrationBuilderStep = ({
  integration,
  company,
  integrationUpdated,
  updateSelectedIntegrationHookStep,
  catalog,
}) => {
  const [integrationState, dispatch] = useReducer(
    IntegrationReducer,
    integrationViewModel
  );
  const [
    disableIntegrationNameIntegration,
    setDisableIntegrationNameIntegration,
  ] = useState(false);
  const didMount = useRef(false);
  const [isSaved, setIsSaved] = React.useState(true);

  useEffect(() => {
    if (company == null || company.integrations == null) return;

    getOrSetIntegration(integration);
  }, [integration]);

  useEffect(() => {
    if (didMount.current) integrationStateUpdated();
    else didMount.current = true;
  }, [integrationState]);

  const getOrSetIntegration = (integration) => {
    if (integration.integrationHookStep == "") {
      updateWebhook(integrationViewModel);
      setIsNew(true);
    } else {
      setIsNew(false);
      updateWebhook(
        company.integrations[integration.integration].hooks[
          integration.integrationHook
        ].steps[integration.integrationHookStep]
      );
    }
    updateIntegration(integration.integration);
  };

  const refreshWebhook = async () => {
    // const tempAgent = await getApiAgent(agent.id);
    // const integrationObject = tempAgent.webhooks[integrationStep[0]];
    // if(integrationObject != null){
    //     updateWebhook(integrationObject);
    //     setTimeout(() => {
    //         setIsSaved(true);
    //     }, 500);
    // }
  };

  const integrationStateUpdated = () => {
    //setIsSaved(false);
  };

  const [httpRequestTypeOut, setHttpRequestTypeOut] = React.useState(false);
  const [authTypeOut, setAuthTypeOut] = React.useState(false);
  const [inputWebhookOut, setInputWebhookOut] = React.useState(false);
  const [invokeWebhookOut, setInvokeWebhookOut] = React.useState(false);
  const [selectedLanguageOut, setSelectedLanguageOut] = React.useState(false);
  const [apiResponseMessage, setApiResponseMessage] = React.useState("");
  const [apiResponseParameters, setApiResponseParameters] = React.useState([]);
  const [isNew, setIsNew] = React.useState(false);

  //const [httpRequestType, setHttpRequestType] = React.useState("GET");
  //const [authenticationType, setAuthenticationType] = React.useState("Basic authentication");
  const [selectedLanguage, setSelectedLanguage] = React.useState("NL");

  //const [inputWebhook, setInputWebhook] = React.useState("");
  //const [invokeWebhook, setInvokeWebhook] = React.useState("");

  const [tabValue, setTabValue] = React.useState(0);
  const [responseBodyJson, setResponseBodyJson] = React.useState({});
  const [newWebhookName, setNewWebhookName] = React.useState("");

  //const [queryParams, setQueryParams] = React.useState([{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""}]);
  //const [headers, setHeaders] = React.useState([{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""}]);
  //const [responses, setResponses] = React.useState([{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""}]);
  //const [testInputFields, setTestInputFields] = React.useState([{key:"ORDERNUMBER", value:""},{key:"PHONENUMBER"}]);

  const updateWebhook = (value) =>
    dispatch({ type: ACTIONS.UPDATE_WEBHOOK, payload: { webhook: value } });
  const updateName = (value) => {
    dispatch({ type: ACTIONS.UPDATE_NAME, payload: { name: value } });
    setIsSaved(false);
  };
  const updateIntegration = (value) =>
    dispatch({
      type: ACTIONS.UPDATE_INTEGRATION,
      payload: { integration: value },
    });
  const updateUrl = (value) => {
    dispatch({ type: ACTIONS.UPDATE_URL, payload: { url: value } });
    setIsSaved(false);
  };
  const updateRequestType = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_REQUEST_TYPE,
      payload: { requestType: value },
    });
    setIsSaved(false);
  };
  const clearRequestStep = () => {
    dispatch({
      type: ACTIONS.CLEAR_REQUEST_STEP,
      payload: {},
    });
  };
  const updateInputWebhook = async (value) => {
    dispatch({
      type: ACTIONS.UPDATE_INPUT_WEBHOOK,
      payload: { inputWebhook: value },
    });
    setIsSaved(false);
  };
  const updateInvokeWebhook = async (value) => {
    dispatch({
      type: ACTIONS.UPDATE_INVOKE_WEBHOOK,
      payload: { invokeWebhook: value },
    });
    setIsSaved(false);
  };
  const updateSettingsOrdernumberRequired = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_ORDERNUMBER_REQUIRED,
      payload: { ordernumberRequired: value },
    });
    setIsSaved(false);
  };
  const updateSettingsPhonenumberRequired = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_PHONENUMBER_REQUIRED,
      payload: { phonenumberRequired: value },
    });
    setIsSaved(false);
  };
  const updateSettingsLoggingActive = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_LOGGIN_ACTIVE,
      payload: { loggingActive: value },
    });
    setIsSaved(false);
  };
  const updateSettingsIsTokenRefreshWebhook = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_IS_TOKEN_REFRESH_WEBHOOK,
      payload: { isTokenRefreshWebhook: value },
    });
    setIsSaved(false);
  };
  const updateSettingsIsCreateTicketWebhook = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_IS_CREATE_TICKET_WEBHOOK,
      payload: { isCreateTicketWebhook: value },
    });
    setIsSaved(false);
  };

  const updateSettingsIsStartStep = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_IS_START_STEP,
      payload: { isStartStep: value },
    });
    setIsSaved(false);
  };
  const updateFormUrlEncoded = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_SETTINGS_FORM_URL_ENCODED,
      payload: { formUrlEncoded: value },
    });
    setIsSaved(false);
  };

  const updateQueryParams = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_QUERYPARAMS,
      payload: { queryParams: value },
    });
    setIsSaved(false);
  };
  const updateHeaders = (value) => {
    dispatch({ type: ACTIONS.UPDATE_HEADERS, payload: { headers: value } });
    setIsSaved(false);
  };
  const updateResponses = (value) => {
    dispatch({ type: ACTIONS.UPDATE_RESPONSES, payload: { responses: value } });
    setIsSaved(false);
  };
  const addExtraResponse = () => {
    dispatch({ type: ACTIONS.ADD_EXTRA_RESPONSE, payload: {} });
    setIsSaved(false);
  };

  const updateTestInputFields = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_TEST_INPUT_FIELDS,
      payload: { testInputFields: value },
    });
    setIsSaved(false);
  };
  const updateBody = (value) => {
    dispatch({ type: ACTIONS.UPDATE_BODY, payload: { body: value } });
    setIsSaved(false);
  };
  const updateAuthenticationType = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_AUTHENTICATION_TYPE,
      payload: { authenticationType: value },
    });
    setIsSaved(false);
  };
  const updateAuthenticationBasicUsername = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_AUTHENTICATION_BASIC_USERNAME,
      payload: { authenticationBasicUsername: value },
    });
    setIsSaved(false);
  };
  const updateAuthenticationBasicPassword = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_AUTHENTICATION_BASIC_PASSWORD,
      payload: { authenticationBasicPassword: value },
    });
    setIsSaved(false);
  };
  const updateAuthenticationBearerToken = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_AUTHENTICATION_BEARER_TOKEN,
      payload: { authenticationBearerToken: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFailedResponse = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FAILED_RESPONSE,
      payload: { language: selectedLanguage, response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFailedResponseEvent = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FAILED_RESPONSE_EVENT,
      payload: { language: selectedLanguage, response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFailedResponseData = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FAILED_RESPONSE_DATA,
      payload: { language: selectedLanguage, response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFailedParams = (key, value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FAILED_PARAMS,
      payload: { param: key, value: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFallbackResponse = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FALLBACK_RESPONSE,
      payload: { language: selectedLanguage, response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFallbackResponseEvent = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FALLBACK_RESPONSE_EVENT,
      payload: { language: selectedLanguage, response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFallbackResponseData = (value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FALLBACK_RESPONSE_DATA,
      payload: { language: selectedLanguage, response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookFallbackParams = (key, value) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_FALLBACK_PARAMS,
      payload: { param: key, value: value },
    });
    setIsSaved(false);
  };

  const updateWebhookGptBody = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_GPT_BODY,
      payload: { response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookGptBodyModel = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_GPT_BODY_MODEL,
      payload: { response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookGptBodyInputVars = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_GPT_BODY_INPUT_VARS,
      payload: { response: value },
    });
    setIsSaved(false);
  };

  const updateWebhookGptResponseKey = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE_KEY,
      payload: { response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookGptResponse = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE,
      payload: { response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookGptResponseModel = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE_MODEL,
      payload: { response: value },
    });
    setIsSaved(false);
  };
  const updateWebhookGptResponseInputVars = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_GPT_RESPONSE_INPUT_VARS,
      payload: { response: value },
    });
    setIsSaved(false);
  };

  const updateWebhookMappingResponse = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE,
      payload: { language: selectedLanguage, index: i, response: value },
    });
    setIsSaved(false);
  };

  const updateWebhookMappingResponseEvent = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_EVENT,
      payload: { language: selectedLanguage, index: i, response: value },
    });
    setIsSaved(false);
  };

  const updateWebhookMappingResponseData = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_DATA,
      payload: { language: selectedLanguage, index: i, response: value },
    });
    setIsSaved(false);
  };

  const updateWebhookMappingParams = (key, value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_PARAMS,
      payload: { index: i, param: key, value: value },
    });
    setIsSaved(false);
  };
  const updateMappingTriggerHook = (triggerStep, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_TRIGGER_STEP,
      payload: { triggerStep: triggerStep, index: i },
    });
    setIsSaved(false);
  };

  const updateMappingSequence = (sequence, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_SEQUENCE,
      payload: { sequence: sequence, index: i },
    });
    setIsSaved(false);
  };

  const updateMappingResponseCondition = (
    key,
    value,
    responseIndex,
    statementIndex
  ) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_CONDITION,
      payload: {
        responseIndex: responseIndex,
        statementIndex: statementIndex,
        key: key,
        value: value,
      },
    });
    setIsSaved(false);
  };
  const updateMappingResponseOperator = (value, i) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_OPERATOR,
      payload: { index: i, value: value },
    });
    setIsSaved(false);
  };
  const updateMappingResponsAddConditionRow = (responseIndex) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_CONDITION_ROW,
      payload: { responseIndex: responseIndex },
    });
    setIsSaved(false);
  };
  const updateMappingResponsDeleteConditionRow = (
    responseIndex,
    statementIndex
  ) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_CONDITION_ROW,
      payload: { responseIndex: responseIndex, statementIndex: statementIndex },
    });
    setIsSaved(false);
  };

  const updateMappingResponsAddRow = () => {
    var seq =
      Number(
        integrationState.agentResponseMapping
          .map((x) => (x.sequence == null ? { ...x, sequence: 0 } : x))
          .sort((a, b) => (a.sequence < b.sequence ? 1 : -1))[0].sequence
      ) + 1;
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_ADD_ROW,
      payload: { sequence: seq },
    });
    setIsSaved(false);
  };
  const updateMappingResponsDeleteRow = (responseIndex) => {
    dispatch({
      type: ACTIONS.UPDATE_WEBHOOK_MAPPING_RESPONSE_DELETE_ROW,
      payload: { responseIndex: responseIndex },
    });
    setIsSaved(false);
  };
  const updateFallbackDeleteRow = (rowIndex) => {
    dispatch({
      type: ACTIONS.UPDATE_FALLBACK_DELETE_ROW,
      payload: { rowIndex: rowIndex },
    });
    setIsSaved(false);
  };

  // const updateMappingResponseConfiguratorObject = (value, key, index) => {
  //     var newState = {...mappingResponseConfiguratorObject};
  //     newState.statements[index][key] = value;
  //     setMappingResponseConfiguratorObject(newState);
  // }

  const toggleHttpRequestType = () => {
    setHttpRequestTypeOut(!httpRequestTypeOut);
  };
  const toggleAuthType = () => {
    setAuthTypeOut(!authTypeOut);
  };
  const toggleInputWebhook = () => {
    setInputWebhookOut(!inputWebhookOut);
  };
  const toggleInvokeWebhook = () => {
    setInvokeWebhookOut(!invokeWebhookOut);
  };
  const toggleSelectedLanguage = () => {
    setSelectedLanguageOut(!selectedLanguageOut);
  };
  const tabChange = (event, val) => {
    setTabValue(val);

    // if logging or fallbacks refresh webhook to get the data
    if (val == 8 || val == 9) {
      refreshWebhook();
    }

    integrationUpdated(val);
  };

  const onChangeJSON = (json) => {
    try {
      var obj = JSON.parse(json);
      updateBody(obj);
    } catch (e) {}
  };

  const [
    mappingResponseConfiguratorObjectIndex,
    setMappingResponseConfiguratorObjectIndex,
  ] = React.useState(null);
  const [openMappingResponseConfigurator, setOpenMappingResponseConfigurator] =
    React.useState(false);
  const handleClickOpenMappingResponseConfigurator = (index) => {
    setMappingResponseConfiguratorObjectIndex(index);
    setOpenMappingResponseConfigurator(true);
  };

  const handleClickCloseMappingResponseConfigurator = () => {
    setMappingResponseConfiguratorObjectIndex(null);
    setOpenMappingResponseConfigurator(false);
  };

  const [
    openNewIntegrationStepConfigurator,
    setOpenNewIntegrationStepConfigurator,
  ] = React.useState(false);
  const handleClickOpenNewIntegrationStepConfigurator = () => {
    setOpenNewIntegrationStepConfigurator(true);
  };

  const handleClickCloseNewIntegrationStepConfiguratorCancel = () => {
    setOpenNewIntegrationStepConfigurator(false);
    setNewWebhookName("");
  };

  const handleClickCloseNewIntegrationStepConfiguratorOk = async () => {
    setOpenNewIntegrationStepConfigurator(false);

    var integrationObject = integrationViewModel;
    integrationObject.integration = integration.integrationHook;
    integrationObject.name = newWebhookName;
    integrationObject.inputWebhook = integrationState.name;

    let name = toCamelCaseFormat(integrationObject.name);
    name = name[0].toUpperCase() + name.substring(1);
    integrationObject.name = name;
    integrationObject.integration =
      integrationObject.integration[0] +
      integrationObject.integration.substring(1);
    //updateName(name);
    integrationObject.longName =
      integrationObject.integration[0] +
      integrationObject.integration.substring(1) +
      "." +
      integrationObject.name;

    await updateInputInvokeSettings(integrationObject);

    integrationUpdated(tabValue);
    updateInvokeWebhook(integrationObject.name);
    setIsSaved(true);
  };
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
  };

  const updateInputInvokeSettings = async (integrationObject) => {
    if (company.name == "Integration catalog") {
      var integrationSet = await getIntegration(integration.integration);
      integrationSet.hooks[integration.integrationHook].steps[
        integrationObject.name
      ] = integrationObject;
      integrationSet.hooks[integration.integrationHook].steps[
        integrationState.name
      ].invokeWebhook = integrationObject.name;
      updateIntegrationDb(integrationSet);
    } else {
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps[integrationObject.name] = integrationObject;
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps[integrationState.name].invokeWebhook = integrationObject.name;
      updateCompanyIntentFields(company);
    }
  };

  const doTestWebhook = async () => {
    if (integrationState.name == "") return;

    setApiResponseMessage("");
    setApiResponseParameters([]);

    let object;
    var isTest = tabValue != 9;

    if (company.name != "Integration catalog") {
      var agents = await getApiAgentsByCompany(company.id);

      var dialogflowId = "";
      if (agents.length != 0) {
        dialogflowId = agents[0].dialogflowAgentId;
      }

      object = {
        CompanyId: company.id,
        DialogflowAgentId: dialogflowId,
        Language: selectedLanguage,
        Webhook: `${integration.integration}|${integration.integrationHook}|${integrationState.name}`,
        Parameters: integrationState.testInputFields,
        ReturnJsonResponse: isTest,
      };
    } else {
      object = {
        CompanyId: "integrationCatalog",
        DialogflowAgentId: "integrationCatalog",
        Language: selectedLanguage,
        Webhook: `${integration.integration}|${integration.integrationHook}|${integrationState.name}`,
        Parameters: integrationState.testInputFields,
        ReturnJsonResponse: isTest,
      };
    }

    testWebhook(object).then((result) => {
      var message = result.fulfillment_response.messages[0].text.text[0];
      console.log(result);
      if (isTest) {
        var ob = result.session_info.parameters.jsonApiResponse;
        if (ob != undefined) {
          var obj = JSON.parse(ob);
          setResponseBodyJson(obj);
        }
      } else {
        var parameters = result.session_info.parameters;
        setApiResponseMessage(message);
        setApiResponseParameters(parameters);
      }
    });
  };

  const addNewWebhook = () => {
    handleClickOpenNewIntegrationStepConfigurator();
    //updateInputWebhook("");
  };
  const save = async () => {
    if (integrationState.name == "" || integrationState.integration == "")
      return;

    var integrationSaveObject = { ...integrationState };
    console.log(integrationSaveObject);

    let requiredObjects = [];
    let urlSplitted = integrationState.url.split(/["/","?","=","&"]/g);
    urlSplitted.map((x) => {
      if (x != "" && x === x.toUpperCase() && !/^\d+$/.test(x)) {
        requiredObjects.push({
          key: x,
          value:
            integrationState.testInputFields.filter((y) => y.key == x)[0]
              ?.value || "",
        });
      }
    });

    integrationState.queryParams.map((x) => {
      if (
        x.key != "" &&
        x.key === x.key.toUpperCase() &&
        !/^\d+$/.test(x.key)
      ) {
        requiredObjects.push({
          key: x.key,
          value:
            integrationState.testInputFields.filter((y) => y.key == x.key)[0]
              ?.value || "",
        });
      }
      if (
        x.value != "" &&
        x.value === x.value.toUpperCase() &&
        !/^\d+$/.test(x.value)
      ) {
        requiredObjects.push({
          key: x.value,
          value:
            integrationState.testInputFields.filter((y) => y.key == x.value)[0]
              ?.value || "",
        });
      }
    });

    if (integrationState.authentication.bearer != null) {
      var token = integrationState.authentication.bearer.token;
      if (
        token != "" &&
        token === token.toUpperCase() &&
        !/^\d+$/.test(token)
      ) {
        requiredObjects.push({
          key: token,
          value:
            integrationState.testInputFields.filter((y) => y.key == token)[0]
              ?.value || "",
        });
      }
    } else if (integrationState.authentication.basic != null) {
      var username = integrationState.authentication.basic.username;
      var password = integrationState.authentication.basic.password;

      if (
        username != "" &&
        username === username.toUpperCase() &&
        !/^\d+$/.test(username)
      ) {
        requiredObjects.push({
          key: username,
          value:
            integrationState.testInputFields.filter((y) => y.key == username)[0]
              ?.value || "",
        });
      }
      if (
        password != "" &&
        password === password.toUpperCase() &&
        !/^\d+$/.test(password)
      ) {
        requiredObjects.push({
          key: password,
          value:
            integrationState.testInputFields.filter((y) => y.key == password)[0]
              ?.value || "",
        });
      }
    }

    // integrationState.authentication.map(x => {
    //     if(x.key != "" && x.key === x.key.toUpperCase() && !/^\d+$/.test(x.key)){
    //         requiredObjects.push({key:x.key, value: integrationState.testInputFields.filter(y => y.key  == x.key)[0]?.value || ''});
    //     }
    //     if(x.value != "" && x.value === x.value.toUpperCase() && !/^\d+$/.test(x.value)){
    //         requiredObjects.push({key:x.value, value: integrationState.testInputFields.filter(y => y.key  == x.value)[0]?.value || ''});
    //     }
    // })

    integrationState.headers.map((x) => {
      if (
        x.key != "" &&
        x.key === x.key.toUpperCase() &&
        !/^\d+$/.test(x.key)
      ) {
        requiredObjects.push({
          key: x.key,
          value:
            integrationState.testInputFields.filter((y) => y.key == x.key)[0]
              ?.value || "",
        });
      }
      if (
        x.value != "" &&
        x.value === x.value.toUpperCase() &&
        !/^\d+$/.test(x.value)
      ) {
        requiredObjects.push({
          key: x.value,
          value:
            integrationState.testInputFields.filter((y) => y.key == x.value)[0]
              ?.value || "",
        });
      }
    });
    let bodyString = JSON.stringify(integrationState.body);
    let splittedBody = bodyString.split(/["/","?","=","&"," ","."]/g);

    let capitals = splittedBody.filter(
      (x) =>
        x
          .replace(/[^A-Z]/g, " ")
          .trim()
          .split(" ") != "" && x == x.toUpperCase()
    );
    let distinctCapitals = Array.from(new Set(capitals));
    distinctCapitals.map((x) =>
      requiredObjects.push({
        key: x,
        value:
          integrationState.testInputFields.filter((y) => y.key == x)[0]
            ?.value || "",
      })
    );
    //bodyString.replace(/[^A-Z]/g, ' ').trim().split(' ').filter(x => x != '').map(x => requiredObjects.push({key:x, value: integrationState.testInputFields.filter(y => y.key  == x)[0]?.value || ''}));

    //updateRequiredParams(requiredObjects);
    integrationSaveObject.testInputFields = requiredObjects;

    let name = toCamelCaseFormat(integrationSaveObject.name);
    name = name[0].toUpperCase() + name.substring(1);
    integrationSaveObject.name = name;
    integrationSaveObject.integration =
      integrationSaveObject.integration[0] +
      integrationSaveObject.integration.substring(1);
    integrationSaveObject.exportParameters = integrationSaveObject.responses
      .filter((x) => x.key != "")
      .map((x) => x.key);
    //updateName(name);
    integrationSaveObject.longName =
      integrationSaveObject.integration[0] +
      integrationSaveObject.integration.substring(1) +
      "." +
      integrationSaveObject.name;

    if (integrationSaveObject.inputWebhook != "") {
      await updateInputIsSet(
        integrationSaveObject.inputWebhook,
        integrationSaveObject.name
      );
    }
    if (integrationSaveObject.invokeWebhook != "") {
      await updateInvokeIsSet(
        integrationSaveObject.invokeWebhook,
        integrationSaveObject.name
      );
    }

    company.integrations[integration.integration].hooks[
      integration.integrationHook
    ].steps[integrationSaveObject.name] = integrationSaveObject;

    var isStartStep = Object.entries(
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps
    ).filter((x) => x[1].settings.isStartStep)[0];
    if (isStartStep != null && isStartStep != "") {
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].settings.startStep = isStartStep[0];
    }

    if (company.name == "Integration catalog") {
      var integrationSet = await getIntegration(integration.integration);
      integrationSet.hooks[integration.integrationHook].steps[
        integrationSaveObject.name
      ] = integrationSaveObject;
      if (isStartStep != null && isStartStep != "") {
        integrationSet.hooks[integration.integrationHook].settings.startStep =
          isStartStep[0];
      }

      updateIntegrationDb(integrationSet);
      updateWebhook(integrationSaveObject);
      setTimeout(() => {
        setIsSaved(true);
        integrationUpdated(tabValue);
        updateSelectedIntegrationHookStep(integrationSaveObject.name);
      }, 500);
    } else {
      //if(integrationSaveObject.invokeWebhook != ""){
      //    agent.integrations[integration.integration].hooks[integration.integrationHook].steps[integrationSaveObject].invokeWebhook = integrationSaveObject.name;
      //}

      updateCompanyIntentFields(company);
      updateWebhook(integrationSaveObject);
      setTimeout(() => {
        setIsSaved(true);
        integrationUpdated(tabValue);
        updateSelectedIntegrationHookStep(integrationSaveObject.name);
      }, 500);
    }
  };

  const updateInvokeIsSet = async (invoke, value) => {
    if (company.name == "Integration catalog") {
      var integrationSet = await getIntegration(integration.integration);
      integrationSet.hooks[integration.integrationHook].steps[
        value
      ].invokeWebhook = invoke;
      integrationSet.hooks[integration.integrationHook].steps[
        invoke
      ].inputWebhook = value;
      updateIntegrationDb(integrationSet);
    } else {
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps[value].invokeWebhook = invoke;
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps[invoke].inputWebhook = value;
      updateCompanyIntentFields(company);
    }
    integrationUpdated(tabValue);
    setIsSaved(true);
  };
  const updateInputIsSet = async (input, value) => {
    if (company.name == "Integration catalog") {
      var integrationSet = await getIntegration(integration.integration);
      integrationSet.hooks[integration.integrationHook].steps[
        value
      ].inputWebhook = input;
      integrationSet.hooks[integration.integrationHook].steps[
        input
      ].invokeWebhook = value;
      updateIntegrationDb(integrationSet);
    } else {
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps[value].inputWebhook = input;
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps[input].invokeWebhook = value;
      updateCompanyIntentFields(company);
    }
    integrationUpdated(tabValue);
    setIsSaved(true);
  };

  const areTestInputFieldsFilled = () =>
    integrationState.testInputFields.filter((x) => x.value == "").length == 0;

  const invokeOrInputWebhookOptions = () =>
    Object.keys(
      company.integrations[integration.integration].hooks[
        integration.integrationHook
      ].steps
    ).filter((x) => x != integrationState.name);

  const isStartStepDisabled = () => {
    if (
      company == null ||
      integration.integration == "" ||
      integration.integrationHook == ""
    )
      return;

    return (
      Object.values(
        company.integrations[integration.integration].hooks[
          integration.integrationHook
        ].steps
      ).filter((x) => x.settings.isStartStep).length > 0
    );
  };

  const [openDeleteStepDialog, setOpenDeleteStepDialog] = useState(false);

  const handleDeleteIntegrationStep = () => {
    if (integrationState.name == "" || integrationState.integration == "")
      return;

    delete company.integrations[integration.integration].hooks[
      integration.integrationHook
    ].steps[integrationState.name];

    updateCompanyIntentFields(company);
    //updateWebhook(integrationSaveObject);
    setTimeout(() => {
      setIsSaved(true);
      integrationUpdated(tabValue);
      setOpenDeleteStepDialog(false);
      //updateSelectedIntegrationHookStep(integrationSaveObject.name);
      clearRequestStep();
    }, 500);
  };
  return (
    <>
      {integrationState?.requestType == null ? (
        <Loading />
      ) : (
        <div className={`${styles.integrationBuilder}`}>
          <div className={styles.urlBuilder}>
            {/* {!disableIntegrationNameIntegration && (
                <input disabled={!isNew || disableIntegrationNameIntegration} value={integrationState.integration} onChange={(e) => updateIntegration(e.target.value)} type="text" className={styles.urlBuilderInput} placeholder="integration"/>
            )} */}
            <input
              disabled={!isNew}
              value={integrationState.name}
              onChange={(e) => updateName(e.target.value)}
              type="text"
              className={styles.urlBuilderInput}
              placeholder="name"
            />

            <div
              className={`${styles.httpRequestType} ${
                httpRequestTypeOut ? styles.httpRequestTypeOut : ""
              }`}
              onClick={toggleHttpRequestType}
            >
              {integrationState.requestType}{" "}
              <IoMdArrowDropdown className={styles.httpRequestTypeIcon} />
              {httpRequestTypeOut && (
                <div className={styles.httpRequestTypeOptions}>
                  <div
                    onClick={() => updateRequestType("GET")}
                    className={styles.httpRequestTypeOptionsRow}
                  >
                    GET
                  </div>
                  <div
                    onClick={() => updateRequestType("POST")}
                    className={styles.httpRequestTypeOptionsRow}
                  >
                    POST
                  </div>
                  <div
                    onClick={() => updateRequestType("PUT")}
                    className={styles.httpRequestTypeOptionsRow}
                  >
                    PUT
                  </div>
                </div>
              )}
            </div>

            <input
              value={integrationState.url}
              onChange={(e) => updateUrl(e.target.value)}
              type="text"
              className={`${styles.urlBuilderInput} ${styles.urlInput}`}
            />
            <div
              onClick={() => setOpenDeleteStepDialog(true)}
              className={`${styles.btnStyle} ${styles.deleteButton} ${
                integrationState.name == "" ||
                integrationState.integration == ""
                  ? styles.btnDisabled
                  : ""
              } `}
            >
              DELETE
            </div>
            <div
              onClick={save}
              className={`${styles.btnStyle} ${
                integrationState.name == "" ||
                integrationState.integration == ""
                  ? styles.btnDisabled
                  : ""
              } ${!isSaved ? styles.saveGlowing : ""}`}
            >
              SAVE
            </div>
            <div
              onClick={doTestWebhook}
              className={`${styles.btnStyle} ${styles.sendButton} ${
                !areTestInputFieldsFilled() || integrationState.name == ""
                  ? styles.disableButton
                  : ""
              }`}
            >
              SEND
            </div>
          </div>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={tabChange}
              aria-label="intent-entity-tabs"
            >
              <Tab {...tabProps(0)} label="Settings" />
              <Tab {...tabProps(1)} label="Query params" />
              <Tab {...tabProps(2)} label="Authorization" />
              <Tab {...tabProps(3)} label="Headers" />
              <Tab
                disabled={integrationState.requestType == "GET"}
                {...tabProps(4)}
                label="Body"
              />
              <Tab {...tabProps(5)} label="GPT Body" />
              <Tab {...tabProps(6)} label="Responses" />
              <Tab {...tabProps(7)} label="GPT Responses" />
              <Tab {...tabProps(8)} label="Mappings" />
              <Tab
                iconPosition="start"
                icon={
                  <div
                    className={`${styles.notificationIcon} ${
                      areTestInputFieldsFilled()
                        ? styles.hideNotificationIcon
                        : ""
                    }`}
                  />
                }
                {...tabProps(7)}
                label="Test"
              />
              <Tab disabled={!isSaved} {...tabProps(8)} label="Logging" />
              <Tab disabled={!isSaved} {...tabProps(9)} label="Fallbacks" />
            </Tabs>
          </Box>

          {tabValue == 0 && (
            <>
              <h3 className={styles.tabHeaderRow}>Settings</h3>

              <div className={styles.formRow}>
                InputWebhook
                <div className={styles.parentWebhookRow}>
                  <div
                    className={`${styles.httpRequestType} ${styles.authType} ${
                      inputWebhookOut ? styles.httpRequestTypeOut : ""
                    }`}
                    onClick={toggleInputWebhook}
                  >
                    {integrationState.inputWebhook}{" "}
                    <IoMdArrowDropdown className={styles.httpRequestTypeIcon} />
                    {inputWebhookOut && (
                      <div className={styles.httpRequestTypeOptions}>
                        <div
                          onClick={() => updateInputWebhook("")}
                          className={styles.httpRequestTypeOptionsRow}
                        ></div>
                        {invokeOrInputWebhookOptions()
                          .filter(
                            (x) =>
                              x != integrationState.name &&
                              x != integrationState.invokeWebhook
                          )
                          .map((wh) => (
                            <div
                              onClick={() => updateInputWebhook(wh)}
                              className={styles.httpRequestTypeOptionsRow}
                            >
                              {wh}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                InvokeWebhook
                <div className={styles.parentWebhookRow}>
                  <div
                    className={`${styles.httpRequestType} ${styles.authType} ${
                      invokeWebhookOut ? styles.httpRequestTypeOut : ""
                    }`}
                    onClick={toggleInvokeWebhook}
                  >
                    {integrationState.invokeWebhook}{" "}
                    <IoMdArrowDropdown className={styles.httpRequestTypeIcon} />
                    {invokeWebhookOut && (
                      <div className={styles.httpRequestTypeOptions}>
                        <div
                          onClick={() => updateInvokeWebhook("")}
                          className={styles.httpRequestTypeOptionsRow}
                        ></div>
                        <div
                          onClick={() => addNewWebhook()}
                          className={styles.httpRequestTypeOptionsRow}
                        >
                          New
                        </div>
                        {invokeOrInputWebhookOptions()
                          .filter(
                            (x) =>
                              x != integrationState.name &&
                              x != integrationState.inputWebhook
                          )
                          .map((wh) => (
                            <div
                              onClick={() => updateInvokeWebhook(wh)}
                              className={styles.httpRequestTypeOptionsRow}
                            >
                              {wh}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Dialog
                open={openNewIntegrationStepConfigurator}
                fullWidth={"lg"}
              >
                <DialogTitle>New integration step</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Add a new integration step to your integration
                  </DialogContentText>
                  <div className={styles.conditionRowContainer}>
                    <div className={styles.conditionRow}>
                      <TextField
                        className={styles.conditionInput}
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        onChange={(e) => setNewWebhookName(e.target.value)}
                        value={newWebhookName}
                        variant="standard"
                      />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={
                      handleClickCloseNewIntegrationStepConfiguratorCancel
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleClickCloseNewIntegrationStepConfiguratorOk}
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>

              <div className={styles.formRow}>
                <FormGroup style={flexContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          updateSettingsOrdernumberRequired(e.target.checked)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        checked={integrationState.settings.requestOrdernumber}
                      />
                    }
                    label={
                      <Box component="div" fontSize={12}>
                        Ordernumber required before webhook is triggered
                      </Box>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          updateSettingsPhonenumberRequired(e.target.checked)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        checked={integrationState.settings.requestPhonenumber}
                      />
                    }
                    label={
                      <Box component="div" fontSize={12}>
                        Phonenumber required before webhook is triggered
                      </Box>
                    }
                  />
                </FormGroup>
              </div>
              <div className={styles.formRow}>
                <FormGroup style={flexContainer}>
                  <FormControlLabel
                    disabled={
                      isStartStepDisabled() &&
                      integrationState.settings.isStartStep == false
                    }
                    control={
                      <Checkbox
                        onChange={(e) =>
                          updateSettingsIsStartStep(e.target.checked)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        checked={integrationState.settings.isStartStep}
                      />
                    }
                    label={
                      <Box component="div" fontSize={12}>
                        Is start step
                      </Box>
                    }
                  />
                </FormGroup>
              </div>
              <div className={styles.formRow}>
                <FormGroup style={flexContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => updateFormUrlEncoded(e.target.checked)}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        checked={integrationState.settings.formUrlEncoded}
                      />
                    }
                    label={
                      <Box component="div" fontSize={12}>
                        Form url encoded
                      </Box>
                    }
                  />
                </FormGroup>
              </div>
              <div className={styles.formRow}>
                <FormGroup style={flexContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          updateSettingsLoggingActive(e.target.checked)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        checked={integrationState.settings.loggingActive}
                      />
                    }
                    label={
                      <Box component="div" fontSize={12}>
                        Logging activated
                      </Box>
                    }
                  />
                </FormGroup>
              </div>
              <div className={styles.formRow}>
                <FormGroup style={flexContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          updateSettingsIsTokenRefreshWebhook(e.target.checked)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        checked={
                          integrationState.settings.isTokenRefreshWebhook
                        }
                      />
                    }
                    label={
                      <Box component="div" fontSize={12}>
                        Is token refresh webhook
                      </Box>
                    }
                  />
                </FormGroup>
              </div>
              <div className={styles.formRow}>
                <FormGroup style={flexContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          updateSettingsIsCreateTicketWebhook(e.target.checked)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        checked={
                          integrationState.settings.isCreateTicketWebhook
                        }
                      />
                    }
                    label={
                      <Box component="div" fontSize={12}>
                        Is create ticket webhook
                      </Box>
                    }
                  />
                </FormGroup>
              </div>
            </>
          )}

          <div
            className={`${styles.tabContainer} ${
              tabValue == 8 || tabValue == 9 || tabValue == 10
                ? styles.tabContainerLarge
                : ""
            }`}
          >
            {tabValue == 1 && (
              <>
                <h3 className={styles.tabHeaderRow}>Query parameters</h3>
                <div className={styles.dictGrid}>
                  <div className={`${styles.dictRow} ${styles.dictHeaderRow}`}>
                    <div className={styles.dictRowKey}>
                      <div className={styles.dictInput}>KEY</div>
                    </div>
                    <div className={styles.dictRowValue}>
                      <div className={styles.dictInput}>VALUE</div>
                    </div>
                    <div className={styles.dictRowDelete}>
                      <div className={styles.dictInput}>DELETE</div>
                    </div>
                  </div>

                  {integrationState.queryParams.map((x, index) => (
                    <DictRow
                      key={index}
                      index={index}
                      collection={integrationState.queryParams}
                      setCollection={updateQueryParams}
                    />
                  ))}
                </div>
              </>
            )}
            {tabValue == 2 && (
              <>
                <h3 className={styles.tabHeaderRow}>Authorization</h3>

                <div className={styles.authRow}>
                  <div
                    className={`${styles.httpRequestType} ${styles.authType} ${
                      authTypeOut ? styles.httpRequestTypeOut : ""
                    }`}
                    onClick={toggleAuthType}
                  >
                    {Object.keys(integrationState.authentication)[0]}{" "}
                    <IoMdArrowDropdown className={styles.httpRequestTypeIcon} />
                    {authTypeOut && (
                      <div className={styles.httpRequestTypeOptions}>
                        <div
                          onClick={() => updateAuthenticationType("basic")}
                          className={styles.httpRequestTypeOptionsRow}
                        >
                          basic
                        </div>
                        <div
                          onClick={() => updateAuthenticationType("bearer")}
                          className={styles.httpRequestTypeOptionsRow}
                        >
                          bearer
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.authForm}>
                    {Object.keys(integrationState.authentication)[0] ==
                      "basic" && (
                      <>
                        <input
                          onChange={(e) =>
                            updateAuthenticationBasicUsername(e.target.value)
                          }
                          value={
                            integrationState.authentication.basic?.username
                          }
                          type="text"
                          className={styles.authInputField}
                          placeholder="username"
                        />
                        <input
                          onChange={(e) =>
                            updateAuthenticationBasicPassword(e.target.value)
                          }
                          value={
                            integrationState.authentication.basic?.password
                          }
                          type="text"
                          className={styles.authInputField}
                          placeholder="password"
                        />
                      </>
                    )}
                    {Object.keys(integrationState.authentication)[0] ==
                      "bearer" && (
                      <>
                        <input
                          onChange={(e) =>
                            updateAuthenticationBearerToken(e.target.value)
                          }
                          value={integrationState.authentication.bearer?.token}
                          type="text"
                          className={styles.authInputField}
                          placeholder="token"
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {tabValue == 3 && (
              <>
                <h3 className={styles.tabHeaderRow}>Headers</h3>
                <div className={styles.dictGrid}>
                  <div className={`${styles.dictRow} ${styles.dictHeaderRow}`}>
                    <div className={styles.dictRowKey}>
                      <div className={styles.dictInput}>KEY</div>
                    </div>
                    <div className={styles.dictRowValue}>
                      <div className={styles.dictInput}>VALUE</div>
                    </div>
                    <div className={styles.dictRowDelete}>
                      <div className={styles.dictInput}>DELETE</div>
                    </div>
                  </div>

                  {integrationState.headers.map((x, index) => (
                    <DictRow
                      key={index}
                      index={index}
                      collection={integrationState.headers}
                      setCollection={updateHeaders}
                    />
                  ))}
                </div>
              </>
            )}
            {tabValue == 4 && (
              <>
                <h3 className={styles.tabHeaderRow}>Body</h3>
                <JSONEditorInstance
                  type="code"
                  json={integrationState.body}
                  onChange={onChangeJSON}
                />
              </>
            )}
            {tabValue == 5 && (
              <>
                <h3 className={styles.tabHeaderRow}>GPT Body</h3>
                <div className={styles.flexRow}>
                  <FormControl
                    fullWidth
                    size="small"
                    className={styles.flexSize1}
                  >
                    <label for="demo-simple-select-gptmodel">Model:</label>
                    <Select
                      id="demo-simple-select-gptmodel"
                      value={integrationState.gptModelBody}
                      onChange={(e) =>
                        updateWebhookGptBodyModel(e.target.value)
                      }
                    >
                      {[
                        { key: 3, value: "3.5-turbo" },
                        { key: 4, value: "4-turbo" },
                      ]?.map((x) => (
                        <MenuItem key={x.key} value={x.key}>
                          {x.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    size="small"
                    className={styles.flexSize3}
                  >
                    <label for="demo-simple-select-gptmodel">
                      Input variables (; seperated) (Empty for all vars):
                    </label>
                    <input
                      onChange={(e) =>
                        updateWebhookGptBodyInputVars(e.target.value)
                      }
                      value={integrationState.gptInputVarsBody}
                      type="text"
                      className={styles.borderInput}
                      placeholder="username"
                    />
                  </FormControl>
                </div>
                <hr />
                <textarea
                  onChange={(e) => updateWebhookGptBody(e.target.value)}
                  value={integrationState.gptBody}
                  className={styles.gptBodyArea}
                  placeholder="GPT Body"
                ></textarea>
              </>
            )}

            {tabValue == 6 && (
              <>
                <h3 className={styles.tabHeaderRow}>Responses</h3>
                <div className={styles.dictGrid}>
                  <div className={`${styles.dictRow} ${styles.dictHeaderRow}`}>
                    <div className={styles.dictRowKey}>
                      <div className={styles.dictInput}>KEY</div>
                    </div>
                    <div className={styles.dictRowValue}>
                      <div className={styles.dictInput}>VALUE</div>
                    </div>
                    <div className={styles.dictRowDelete}>
                      <div className={styles.dictInput}>DELETE</div>
                    </div>
                  </div>

                  {integrationState.responses.map((x, index) => (
                    <DictRow
                      key={index}
                      index={index}
                      collection={integrationState.responses}
                      setCollection={updateResponses}
                      keyIsUpper={true}
                    />
                  ))}
                  <div className="flex justify-center p-2 text-4xl">
                    <div
                      onClick={addExtraResponse}
                      className="cursor-pointer px-6"
                    >
                      <IoMdAdd />
                    </div>
                  </div>
                </div>
              </>
            )}

            {tabValue == 7 && (
              <>
                <h3 className={styles.tabHeaderRow}>GPT Responses</h3>
                <input
                  value={integrationState.gptResponseMappingKey}
                  onChange={(e) => updateWebhookGptResponseKey(e.target.value)}
                  type="text"
                  className={styles.gptResponseLabelInput}
                  placeholder="key"
                />

                <div className={styles.flexRow}>
                  <FormControl
                    fullWidth
                    size="small"
                    className={styles.flexSize1}
                  >
                    <label for="demo-simple-select-gptmodel">Model:</label>
                    <Select
                      id="demo-simple-select-gptmodel"
                      value={integrationState.gptResponseModelMapping}
                      onChange={(e) =>
                        updateWebhookGptResponseModel(e.target.value)
                      }
                    >
                      {[
                        { key: 3, value: "3.5-turbo" },
                        { key: 4, value: "4-turbo" },
                      ]?.map((x) => (
                        <MenuItem key={x.key} value={x.key}>
                          {x.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    size="small"
                    className={styles.flexSize3}
                  >
                    <label for="demo-simple-select-gptmodel">
                      Input variables (; seperated) (Empty for all vars):
                    </label>
                    <input
                      onChange={(e) =>
                        updateWebhookGptResponseInputVars(e.target.value)
                      }
                      value={integrationState.gptResponseInputVarsMapping}
                      type="text"
                      className={styles.borderInput}
                      placeholder="username"
                    />
                  </FormControl>
                </div>
                <hr />
                <textarea
                  onChange={(e) => updateWebhookGptResponse(e.target.value)}
                  value={integrationState.gptResponseMapping}
                  className={styles.gptBodyArea}
                  placeholder="GPT Response"
                ></textarea>
              </>
            )}

            {tabValue == 8 && (
              <>
                <div
                  className={`${styles.authRow} ${styles.mappingsLanguageSelect}`}
                >
                  <div
                    className={`${styles.httpRequestType} ${styles.authType} ${
                      authTypeOut ? styles.httpRequestTypeOut : ""
                    }`}
                    onClick={toggleSelectedLanguage}
                  >
                    {selectedLanguage}{" "}
                    <IoMdArrowDropdown className={styles.httpRequestTypeIcon} />
                    {selectedLanguageOut && (
                      <div className={styles.httpRequestTypeOptions}>
                        <div
                          onClick={() => setSelectedLanguage("NL")}
                          className={styles.httpRequestTypeOptionsRow}
                        >
                          NL
                        </div>
                        <div
                          onClick={() => setSelectedLanguage("DE")}
                          className={styles.httpRequestTypeOptionsRow}
                        >
                          DE
                        </div>
                        <div
                          onClick={() => setSelectedLanguage("EN")}
                          className={styles.httpRequestTypeOptionsRow}
                        >
                          EN
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <h3 className={styles.tabHeaderRow}>Response Mappings</h3>
                <div className={styles.mappings}>
                  {integrationState.agentResponseMapping
                    .map((resp, index) => ({
                      ...resp,
                      originalIndex: index,
                    }))
                    .sort((a, b) => (a.sequence < b.sequence ? -1 : 1))
                    .map((resp, index) => (
                      <>
                        <div
                          className={styles.responseRowContainer}
                          key={"resp" + resp.originalIndex}
                        >
                          <div className={styles.contRow}>
                            <div className={styles.authRow}>
                              <div
                                className={styles.condition}
                                onClick={() =>
                                  handleClickOpenMappingResponseConfigurator(
                                    resp.originalIndex
                                  )
                                }
                              >
                                <div className={styles.conditionText}>
                                  {resp.statements.map((statement, i) => (
                                    <div
                                      className={styles.statementSummaryRow}
                                      key={"statement" + i}
                                    >
                                      {statement.inOperator && i > 0 && (
                                        <div
                                          className={`${styles.statementSummaryItem} ${styles.operatorStyle}`}
                                        >
                                          {statement.inOperator}
                                        </div>
                                      )}
                                      {statement.inOperator != "" && (
                                        <div
                                          className={
                                            styles.statementSummaryItem
                                          }
                                        >
                                          (
                                        </div>
                                      )}
                                      <div
                                        className={styles.statementSummaryItem}
                                      >
                                        {statement.left}
                                      </div>
                                      <div
                                        className={`${styles.statementSummaryItem} ${styles.operatorStyle}`}
                                      >
                                        {statement.operator}
                                      </div>
                                      <div
                                        className={styles.statementSummaryItem}
                                      >
                                        {statement.right}
                                      </div>
                                      {statement.outOperator && (
                                        <div
                                          className={`${styles.statementSummaryItem} ${styles.operatorStyle}`}
                                        >
                                          {statement.outOperator}
                                        </div>
                                      )}
                                      {statement.outOperator == "" && (
                                        <div
                                          className={
                                            styles.statementSummaryItem
                                          }
                                        >
                                          )
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                                <div className={styles.conditionIcon}>
                                  <BiPencil />
                                </div>
                              </div>
                              <div className={styles.mappingResponse}>
                                {/* <div className={styles.mappingResponseTitle}>Response [{selectedLanguage}]</div> */}
                                <div className={styles.deviation}>
                                  <div className={styles.deviationTitle}>
                                    Message:
                                  </div>
                                  <div className={styles.deviationText}>
                                    <textarea
                                      onChange={(e) =>
                                        updateWebhookMappingResponse(
                                          e.target.value,
                                          resp.originalIndex
                                        )
                                      }
                                      value={
                                        integrationState.agentResponseMapping[
                                          resp.originalIndex
                                        ].then.responses[selectedLanguage]
                                          ?.message != null
                                          ? integrationState
                                              .agentResponseMapping[
                                              resp.originalIndex
                                            ].then.responses[selectedLanguage]
                                              ?.message
                                          : integrationState
                                              .agentResponseMapping[
                                              resp.originalIndex
                                            ].then.responses[selectedLanguage]
                                      }
                                      className={styles.authInputField}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className={styles.deviation}>
                                  <div className={styles.deviationTitle}>
                                    Event:
                                  </div>
                                  <div className={styles.deviationText}>
                                    <textarea
                                      onChange={(e) =>
                                        updateWebhookMappingResponseEvent(
                                          e.target.value,
                                          resp.originalIndex
                                        )
                                      }
                                      value={
                                        integrationState.agentResponseMapping[
                                          resp.originalIndex
                                        ].then.responses[selectedLanguage]
                                          ?.event != null
                                          ? integrationState
                                              .agentResponseMapping[
                                              resp.originalIndex
                                            ].then.responses[selectedLanguage]
                                              ?.event
                                          : ""
                                      }
                                      className={styles.authInputField}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className={styles.deviation}>
                                  <div className={styles.deviationTitle}>
                                    Data:
                                  </div>
                                  <div className={styles.deviationText}>
                                    <textarea
                                      onChange={(e) =>
                                        updateWebhookMappingResponseData(
                                          e.target.value,
                                          resp.originalIndex
                                        )
                                      }
                                      value={
                                        integrationState.agentResponseMapping[
                                          resp.originalIndex
                                        ].then.responses[selectedLanguage]
                                          ?.data != null
                                          ? integrationState
                                              .agentResponseMapping[
                                              resp.originalIndex
                                            ].then.responses[selectedLanguage]
                                              ?.data
                                          : ""
                                      }
                                      className={styles.authInputField}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.addResponseRow}>
                              <div className={styles.responseRowButton}>
                                <AiOutlineMinus
                                  onClick={() =>
                                    updateMappingResponsDeleteRow(
                                      resp.originalIndex
                                    )
                                  }
                                  className={styles.deleteRowMappingIcon}
                                />
                              </div>
                              {integrationState.agentResponseMapping.length -
                                1 ==
                                index && (
                                <div className={styles.responseRowButton}>
                                  <IoMdAdd
                                    onClick={() => updateMappingResponsAddRow()}
                                    className={styles.addRowMappingIcon}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={styles.triggerHookRow}>
                          <div className={styles.triggerHookHeader}>
                            Trigger webhook step
                          </div>
                          <div className={styles.triggerHookBody}>
                            <FormControl
                              fullWidth
                              className={styles.webhookTriggerSelectContainer}
                            >
                              <Select
                                className={styles.webhookTriggerSelect}
                                value={
                                  integrationState.agentResponseMapping[
                                    resp.originalIndex
                                  ].then.triggerStep
                                }
                                onChange={(e) =>
                                  updateMappingTriggerHook(
                                    e.target.value,
                                    resp.originalIndex
                                  )
                                }
                              >
                                <MenuItem value={""}>{""}</MenuItem>
                                {Object.keys(
                                  company.integrations[integration.integration]
                                    .hooks[integration.integrationHook].steps
                                ).map((x) => (
                                  <MenuItem key={x} value={x}>
                                    {x}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className={styles.triggerHookHeader}>
                            Sequence
                          </div>
                          <div>
                            <input
                              onChange={(e) =>
                                updateMappingSequence(
                                  e.target.value,
                                  resp.originalIndex
                                )
                              }
                              value={
                                integrationState.agentResponseMapping[
                                  resp.originalIndex
                                ].sequence
                              }
                              type="number"
                              className={`${styles.borderInput} p-2`}
                              placeholder="sequence"
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>

                <Dialog
                  open={openMappingResponseConfigurator}
                  onClose={handleClickCloseMappingResponseConfigurator}
                  fullWidth
                  maxWidth={"lg"}
                >
                  <DialogTitle>Response mapping</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Add or update your response mappings based on one or
                      mulitple conditions
                    </DialogContentText>
                    <div className={styles.conditionRowContainer}>
                      {integrationState.agentResponseMapping[
                        mappingResponseConfiguratorObjectIndex
                      ]?.statements.map((x, i) => (
                        <div
                          className={styles.conditionRow}
                          key={"condition" + i}
                        >
                          <FormControl
                            fullWidth
                            className={styles.conditionSelectContainer}
                          >
                            <Select
                              className={styles.conditionSelect}
                              value={x?.inOperator}
                              onChange={(e) =>
                                updateMappingResponseCondition(
                                  "inOperator",
                                  e.target.value,
                                  mappingResponseConfiguratorObjectIndex,
                                  i
                                )
                              }
                            >
                              <MenuItem value={""}>{""}</MenuItem>
                              <MenuItem value={"AND"}>{"AND"}</MenuItem>
                              <MenuItem value={"OR"}>{"OR"}</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            className={styles.conditionInput}
                            autoFocus
                            margin="dense"
                            type="text"
                            fullWidth
                            onChange={(e) =>
                              updateMappingResponseCondition(
                                "left",
                                e.target.value,
                                mappingResponseConfiguratorObjectIndex,
                                i
                              )
                            }
                            value={x?.left}
                            variant="standard"
                          />
                          <FormControl
                            fullWidth
                            className={styles.conditionSelectContainer}
                          >
                            <Select
                              className={styles.conditionSelect}
                              onChange={(e) =>
                                updateMappingResponseCondition(
                                  "operator",
                                  e.target.value,
                                  mappingResponseConfiguratorObjectIndex,
                                  i
                                )
                              }
                              value={x?.operator}
                            >
                              <MenuItem value={"=="}>{"=="}</MenuItem>
                              <MenuItem value={"!="}>{"!="}</MenuItem>
                              <MenuItem value={"<="}>{"<="}</MenuItem>
                              <MenuItem value={">="}>{">="}</MenuItem>
                              <MenuItem value={"CONTAINS"}>
                                {"CONTAINS"}
                              </MenuItem>
                              <MenuItem value={"NOT_CONTAINS"}>
                                {"CONTAINS NOT"}
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            className={styles.conditionInput}
                            onChange={(e) =>
                              updateMappingResponseCondition(
                                "right",
                                e.target.value,
                                mappingResponseConfiguratorObjectIndex,
                                i
                              )
                            }
                            margin="dense"
                            type="text"
                            fullWidth
                            value={x?.right}
                            variant="standard"
                          />
                          <FormControl
                            fullWidth
                            className={styles.conditionSelectContainer}
                          >
                            <Select
                              className={styles.conditionSelect}
                              value={x?.outOperator}
                              onChange={(e) =>
                                updateMappingResponseCondition(
                                  "outOperator",
                                  e.target.value,
                                  mappingResponseConfiguratorObjectIndex,
                                  i
                                )
                              }
                            >
                              <MenuItem value={""}>{""}</MenuItem>
                              <MenuItem value={"AND"}>{"AND"}</MenuItem>
                              <MenuItem value={"OR"}>{"OR"}</MenuItem>
                            </Select>
                          </FormControl>
                          <div className={styles.addConditionRowIconContainer}>
                            <AiFillMinusCircle
                              onClick={() =>
                                updateMappingResponsDeleteConditionRow(
                                  mappingResponseConfiguratorObjectIndex,
                                  i
                                )
                              }
                              className={styles.deleteRowToConditionsIcon}
                            />
                            {integrationState.agentResponseMapping[
                              mappingResponseConfiguratorObjectIndex
                            ]?.statements.length -
                              1 ==
                              i && (
                              <IoAddCircleSharp
                                onClick={() =>
                                  updateMappingResponsAddConditionRow(
                                    mappingResponseConfiguratorObjectIndex
                                  )
                                }
                                className={styles.addRowToConditionsIcon}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClickCloseMappingResponseConfigurator}
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                <h3
                  className={`${styles.tabHeaderRow} ${styles.fallbackHeader}`}
                >
                  Fallback mappings
                </h3>
                <div className={styles.mappings}>
                  <div className={styles.responseRowContainer}>
                    <div className={styles.authRow}>
                      <div className={styles.mappingResponse}>
                        {/* <div className={styles.mappingResponseTitle}>Response [{selectedLanguage}]</div> */}
                        <div className={styles.deviation}>
                          <div className={styles.deviationTitle}>Message:</div>
                          <div className={styles.deviationText}>
                            <textarea
                              onChange={(e) =>
                                updateWebhookFallbackResponse(e.target.value)
                              }
                              value={
                                integrationState.mappingFallbackResponseValues
                                  .responses?.[selectedLanguage]?.message !=
                                null
                                  ? integrationState
                                      .mappingFallbackResponseValues
                                      .responses?.[selectedLanguage]?.message
                                  : integrationState
                                      .mappingFallbackResponseValues
                                      .responses?.[selectedLanguage]
                              }
                              className={styles.authInputField}
                            ></textarea>
                          </div>
                        </div>
                        <div className={styles.deviation}>
                          <div className={styles.deviationTitle}>Event:</div>
                          <div className={styles.deviationText}>
                            <textarea
                              onChange={(e) =>
                                updateWebhookFallbackResponseEvent(
                                  e.target.value
                                )
                              }
                              value={
                                integrationState.mappingFallbackResponseValues
                                  .responses?.[selectedLanguage]?.event != null
                                  ? integrationState
                                      .mappingFallbackResponseValues
                                      .responses?.[selectedLanguage]?.event
                                  : ""
                              }
                              className={styles.authInputField}
                            ></textarea>
                          </div>
                        </div>
                        <div className={styles.deviation}>
                          <div className={styles.deviationTitle}>Data:</div>
                          <div className={styles.deviationText}>
                            <textarea
                              onChange={(e) =>
                                updateWebhookFallbackResponseData(
                                  e.target.value
                                )
                              }
                              value={
                                integrationState.mappingFallbackResponseValues
                                  .responses?.[selectedLanguage]?.data != null
                                  ? integrationState
                                      .mappingFallbackResponseValues
                                      .responses?.[selectedLanguage]?.data
                                  : ""
                              }
                              className={styles.authInputField}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.addResponseRow}></div>
                  </div>
                </div>

                <h3
                  className={`${styles.tabHeaderRow} ${styles.fallbackHeader}`}
                >
                  Webhook failed mappings
                </h3>
                <div className={styles.mappings}>
                  <div className={styles.responseRowContainer}>
                    <div className={styles.authRow}>
                      <div className={styles.mappingResponse}>
                        {/* <div className={styles.mappingResponseTitle}>Response [{selectedLanguage}]</div> */}
                        <div className={styles.deviation}>
                          <div className={styles.deviationTitle}>Message:</div>
                          <div className={styles.deviationText}>
                            <textarea
                              onChange={(e) =>
                                updateWebhookFailedResponse(e.target.value)
                              }
                              value={
                                integrationState.failed.responses?.[
                                  selectedLanguage
                                ]?.message != null
                                  ? integrationState.failed.responses?.[
                                      selectedLanguage
                                    ]?.message
                                  : integrationState.failed.responses?.[
                                      selectedLanguage
                                    ]
                              }
                              className={styles.authInputField}
                            ></textarea>
                          </div>
                        </div>
                        <div className={styles.deviation}>
                          <div className={styles.deviationTitle}>Event:</div>
                          <div className={styles.deviationText}>
                            <textarea
                              onChange={(e) =>
                                updateWebhookFailedResponseEvent(e.target.value)
                              }
                              value={
                                integrationState.failed.responses?.[
                                  selectedLanguage
                                ]?.event != null
                                  ? integrationState.failed.responses?.[
                                      selectedLanguage
                                    ]?.event
                                  : ""
                              }
                              className={styles.authInputField}
                            ></textarea>
                          </div>
                        </div>
                        <div className={styles.deviation}>
                          <div className={styles.deviationTitle}>Data:</div>
                          <div className={styles.deviationText}>
                            <textarea
                              onChange={(e) =>
                                updateWebhookFailedResponseData(e.target.value)
                              }
                              value={
                                integrationState.failed.responses?.[
                                  selectedLanguage
                                ]?.data != null
                                  ? integrationState.failed.responses?.[
                                      selectedLanguage
                                    ]?.data
                                  : ""
                              }
                              className={styles.authInputField}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.addResponseRow}></div>
                  </div>
                </div>
              </>
            )}

            {tabValue == 9 && (
              <>
                <h3 className={styles.tabHeaderRow}>
                  Required test parameters
                </h3>
                <div className={styles.dictGrid}>
                  <div className={`${styles.dictRow} ${styles.dictHeaderRow}`}>
                    <div className={styles.dictRowKey}>
                      <div className={styles.dictInput}>KEY</div>
                    </div>
                    <div className={styles.dictRowValue}>
                      <div className={styles.dictInput}>VALUE</div>
                    </div>
                  </div>

                  {integrationState.testInputFields.map((x, index) => (
                    <DictRow
                      isDeleteble={false}
                      onlyValueEditable={true}
                      key={index}
                      index={index}
                      collection={integrationState.testInputFields}
                      setCollection={updateTestInputFields}
                    />
                  ))}
                </div>

                <div className={styles.testResults}>
                  <h3 className={styles.tabHeaderRow}>Test results</h3>
                  <div className={styles.apiResponseMessage}>
                    <div className={styles.apiReponseHeader}>
                      <strong>Response message:</strong>
                    </div>
                    <div className={styles.apiReponseResult}>
                      {apiResponseMessage}
                    </div>
                  </div>
                  <div className={styles.apiResponseParameters}>
                    <div className={styles.apiReponseHeader}>
                      <strong>Response parameters:</strong>
                    </div>
                    <div className={styles.apiReponseResult}>
                      {Object.entries(apiResponseParameters).map((entry) => {
                        const [key, value] = entry;
                        return (
                          <div
                            key={key}
                            className={styles.apiResponseParameterRow}
                          >
                            {key} : {value}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}

            {tabValue == 10 && (
              <>
                <h3 className={styles.tabHeaderRow}>Logging</h3>
              </>
            )}

            {tabValue == 11 && (
              <>
                <h3 className={styles.tabHeaderRow}>Fallbacks</h3>
                <div className={styles.fallbackContainer}>
                  {integrationState.fallbacks?.map((element, index) => (
                    <div className={styles.fallbackRow}>
                      <div className={styles.fallbackColumn}>
                        <GrStatusUnknown />
                      </div>
                      {Object.entries(element).map((entry) => {
                        const [key, value] = entry;
                        return (
                          <div className={styles.fallbackColumn}>
                            <strong>{key}</strong> : {value}
                          </div>
                        );
                      })}
                      <div
                        onClick={() => updateFallbackDeleteRow(index)}
                        className={styles.fallbackColumnDelete}
                      >
                        <IoClose className={styles.fallbackDeleteIcon} />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          {tabValue != 6 && tabValue != 7 && tabValue != 8 && tabValue != 9 && (
            <div className={styles.httpResponseBody}>
              <h3 className={styles.tabHeaderRow}>Api Response</h3>
              <JSONEditorInstance type="code" json={responseBodyJson} />
            </div>
          )}
        </div>
      )}

      <Dialog open={openDeleteStepDialog} fullWidth={"lg"}>
        <DialogTitle>Delete integrationstep</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete integrationstep
            <strong>{integrationState.name}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteStepDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteIntegrationStep}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default IntegrationBuilderStep;
