import React, { useState, useEffect, useMemo } from "react";
import { IoClose } from "react-icons/io5";

import styles from "styles/admin/integration/dictRow.module.css";

const DictRow = ({
  index,
  collection,
  setCollection,
  keyIsUpper,
  isDeleteble = true,
  onlyValueEditable = false,
}) => {
  const updateKey = (e) => {
    const newState = collection.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          key: keyIsUpper ? e.target.value.toUpperCase() : e.target.value,
        };
      }
      return obj;
    });
    setCollection(newState);
  };
  const updateValue = (e) => {
    const newState = collection.map((obj, i) => {
      if (i === index) {
        return { ...obj, value: e.target.value };
      }
      return obj;
    });
    setCollection(newState);
  };
  const clearRow = () => {
    const newState = collection.map((obj, i) => {
      if (i === index) {
        return { ...obj, key: "", value: "" };
      }
      return obj;
    });
    setCollection(newState);
  };
  return (
    <div className={styles.dictRow}>
      <div className={styles.dictRowKey}>
        {onlyValueEditable && collection[index].key}
        {!onlyValueEditable && (
          <input
            onChange={updateKey}
            className={styles.dictInput}
            type="text"
            value={collection[index].key}
          />
        )}
      </div>
      <div className={styles.dictRowValue}>
        <input
          onChange={updateValue}
          className={styles.dictInput}
          type="text"
          value={collection[index].value}
        />
      </div>
      {isDeleteble && (
        <div className={styles.dictRowDelete}>
          {(collection[index].key != "" || collection[index].value != "") && (
            <IoClose onClick={clearRow} className={styles.deleteDictRowIcon} />
          )}
        </div>
      )}
    </div>
  );
};

export default DictRow;
